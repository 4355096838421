import React from "react";

import { Routes, BrowserRouter, Route, Navigate } from "react-router-dom";

import Foot from "../Components/Foot";

import Principal from "../Components/Principal";

import GridMater from "../Components/GridMater";

import GridColab from "../Components/GridColab";
import ColabItem from "../Components/GridColab/ColabItem";

import GridEquipe from "../Components/GridEquipe";
import EquipeItem from "../Components/GridColab/ColabItem";

import { dataColab } from "../Components/GridColab/data.js";
import dataEquip from "../Components/GridEquipe/data.js";

import About from "../Components/About";
import MyNavbar from "../Components/MyNavbar";
import { MainProvider } from "../Contexts/main";
import MySwipeableDrawer from "../Components/MySwipeableDrawer";
import Contato from "../Components/Contact";
import MyLoading from "../Components/MyLoading";
import MyHelmet from "../Components/MyHelmet";
import MateriasYou from "../Components/MateriasYou";

// const router = createBrowserRouter([
//   { path: "/", element: <Principal /> },
//   //  { path: "/materias/:id", element: <MateriasYou /> },
//   { path: "/materias", element: <GridMater /> },
//   { path: "/patrocinadores/:id", element: <ColabItem data={dataColab} namePath="Patrocinadores" descButton="Todos os Patrocinadores" pathName="/patrocinadores" /> },
//   { path: "/patrocinadores", element: <GridColab /> },
//   { path: "/equipe/:id", element: <EquipeItem data={dataEquip} descButton="Toda a Equipe" namePath="Equipe" pathName="/equipe" /> },
//   { path: "/equipe", element: <GridEquipe /> },
//   { path: "/sobre", element: <About /> },
//   { path: "*", element: <Navigate to="/" /> }
// ]);

export default function ReactRouter() {
  return (

    <BrowserRouter>
      <MainProvider>
        <MyHelmet />
        <MyLoading />
        <MyNavbar />
        <Contato />
        <MySwipeableDrawer />
        <Routes>
          <Route
            path="/"
            Component={() => <Principal />}
          />
          <Route
            path="/materias"
            Component={() => <GridMater />}
          />
          <Route
            path="/patrocinadores/:id"
            Component={() => <ColabItem
              data={dataColab}
              namePath="Patrocinadores"
              descButton="Todos os Patrocinadores"
              pathName="/patrocinadores"
            />
            }
          />
          <Route
            path="/patrocinadores"
            Component={() => <GridColab />}
          />
          <Route
            path="/equipe/:id"
            Component={() =>
              <EquipeItem
                data={dataEquip}
                descButton="Toda a Equipe"
                namePath="Equipe"
                pathName="/equipe"
              />
            }
          />
          <Route
            path="/equipe"
            Component={() => <GridEquipe />}
          />
          <Route
            path="/sobre"
            Component={() => <About />}
          />
          <Route path="*" Component={() => <Navigate to="/" />} />
        </Routes >
        <Foot />
      </MainProvider>
    </BrowserRouter>
  )
}
