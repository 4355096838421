import React from "react";
import { Modal, useTheme, useMediaQuery, LinearProgress } from "@material-ui/core";
import { useMain } from "../../Contexts/main";
import { ContentAnim } from "./styles";
import MyImageLazzy from "../MyImageLazzy";

const logoAnim = "https://d13x8u4oijb9vw.cloudfront.net/gif-anim-no-bg.gif";

export default function MyLoading() {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const { onLoad } = useMain();

    return (
        <div>
            <Modal
                style={{ border: 0, zIndex: 1000000 }}
                disableRestoreFocus
                disableEnforceFocus
                disableAutoFocus
                open={onLoad}
                children={
                    <>
                        <LinearProgress style={{ zIndex: 1 }} color="secondary" />
                        <ContentAnim theme={theme}>
                            <MyImageLazzy
                                title=""
                                isEnteringGif
                                backgroundColor="transparent"
                                height={isSm ? "200px" : "300px"}
                                width={isSm ? "200px" : "300px"}
                                image={logoAnim}
                                disableBackgroundLinear
                            />
                        </ContentAnim>
                    </>
                }
            />
        </div>
    )
}