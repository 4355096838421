import { ImgBdrSobre } from "../About";
import { ImgEquipeBdr } from "../GridEquipe";
import { ImagemMaterias } from "../GridMater/data";

const descLeft =
  "Reportagens jornalisticas que contam histórias, relatam experiências da vida rural, tendências do mercado, além das inovações tecnológicas aplicadas ao campo.";

const descCenter = 'Contamos com nosso time de profissionais altamente qualificados e com experiência no jornalismo há mais de 10 anos.';

const descRight =
  "Saiba mais sobre o programa que tem mais de 600 mil telespectadores e mais de 15 mil seguidores nas redes sociais.";

const descFirst = "Conheça";
const descSec =
  "Nosso conteúdo e equipe";

export default {
  descFirst: descFirst,
  descSec: descSec,

  titleLeft: "Matérias",
  descLeft: descLeft,
  btnLeft: "Confira",
  pathNameLeft: "/materias",

  titleMid: "Equipe",
  descMid: descCenter,
  btnMid: "Conheça",
  pathNameMid: "/equipe",

  titleRight: "Sobre",
  descRight: descRight,
  btnRight: "Saiba mais",
  pathNameRight: "/sobre",

  image: [
    { src: ImagemMaterias, title: "Plantação Rural" },
    { src: ImgEquipeBdr, title: "Equipe do Bom Dia Rural" },
    { src: ImgBdrSobre, title: "Sobre o Bom Dia Rural" }
  ]
};
