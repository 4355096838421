import React, { createContext, useContext, useEffect, useState } from "react";
import { IMain, IProps } from "./interface";
import { useLocation } from 'react-router-dom';

const MainContext = createContext<IMain>({ openSwipe: false, setOpenSwipe: () => { }, openContact: false, setContact: () => { }, onLoad: true, setLoad: () => { }, animIntroComplete: false, setAnimIntroComplete: () => { } });

export const MainProvider = (props: IProps) => {
    const location = useLocation();
    const [openSwipe, setOpenSwipe] = useState(false);
    const [openContact, setContact] = useState(false);

    const [onLoad, setLoad] = useState(false);
    const [onLoadPage, setLoadPage] = useState(false);
    const [animIntroComplete, setAnimIntroComplete] = useState(false);

    useEffect(() => {
        addEventListener("load", (e) => setLoadPage(true));
        return () => removeEventListener("load", (e) => { });
    }, []);

    useEffect(() => {
        if (location.pathname !== "/") {
            setAnimIntroComplete(true);
        }
        window.scrollTo({ top: 0, behavior: 'auto' });
    }, [location.pathname]);

    useEffect(() => {
        if (onLoadPage && animIntroComplete) {
            setLoad(false);
        }
    }, [onLoadPage, animIntroComplete]);

    // useEffect(() => {
    // }, []);

    // useEffect(() => {
    //     Aos.init({ once: true });

    // }, [location.pathname]);
    // const [firstAnimComplete, setFirstAnimComplete] = useState(false);

    // useEffect(() => {
    //     handleSetFirstAnimComplete(false);
    //     // handleSetFirstAnimComplete(location.pathname !== '/');
    //     // if (location.pathname === '/') {
    //     //     handleSetFirstAnimComplete(isSm);
    //     // } else {
    //     //     handleSetFirstAnimComplete(true);
    //     // }
    //     // else if (location.pathname.includes('/materias')) {
    //     //     handleSetFirstAnimComplete()
    //     // } else if (location.pathname.includes('/colaboradores')) {
    //     //     handleSetFirstAnimComplete()
    //     // } else if (location.pathname.includes('/equipe')) {
    //     //     handleSetFirstAnimComplete()
    //     // } else if (location.pathname === '/sobre') {
    //     //     handleSetFirstAnimComplete()
    //     // }
    // }, [location.pathname, isSm]);

    // const handleSetFirstAnimComplete = (ASetFAnimCompl: boolean) => {
    //     // if (!openContact) {
    //         setFirstAnimComplete(ASetFAnimCompl);
    //     // }
    // }

    return (
        <MainContext.Provider
            value={{
                openSwipe,
                setOpenSwipe,
                openContact,
                setContact,
                onLoad,
                setLoad,
                animIntroComplete,
                setAnimIntroComplete
            }}
        >
            {props.children}
        </MainContext.Provider>
    )
}

export function useMain() {
    const context = useContext(MainContext);
    return context;
}