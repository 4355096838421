import { Container, makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const ContentRelative = styled.div`  
  display: flex;
  width: 100%;
  justify-content: center;
  min-height: 80vh;  
  padding-bottom: 45px;  
`;

export const ContainerComp = styled.div`
  display: inherit;
  overflow: "hidden";
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ContainerSecComp = styled(Container)`
  width: 100%;
  padding-top: 70px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
`;

export const useStyles = makeStyles((theme) => ({
  cardMedia: {
    minHeight: 100,
    height: "auto",
    backgroundColor: "#fff",
    borderRadius: 9,
    overflow: "hidden",
    width: "inherit",
  },
  cardPrinc: {
    height: "100%",
    margin: 0,
    padding: 0,
    width: "100%",
    borderRadius: "9px",
    [theme.breakpoints.down("sm")]: {
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center"
    }
  },
}));

export const ContentPrinc = styled.div`  
  position: relative;
  width: 100%;
`;

export const ContentBgColor = styled.main`
  min-height: 100vh;
  width: 100%;
  padding-bottom: 60px;
  background-color: ${props => props.$bgcolor};
`;
