import { motion } from "framer-motion";
import styled from "styled-components";
import { IContentAnim, IContentBgColor } from "./interface";

export const ContentAnim = styled(motion.div)<IContentAnim>(({ theme, height, isequipe, width }) => ({
    height: height,
    width: width,
    borderRadius: 'inherit',
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
        height: isequipe ? 400 : height,
    },
    [theme.breakpoints.up('sm')]: {
        height: isequipe ? 450 : height,
    },
    [theme.breakpoints.up('md')]: {
        height: isequipe ? 500 : height,
    },
    [theme.breakpoints.up('lg')]: {
        height: isequipe ? 550 : height,
    },
}));


export const ContentBgColor = styled.div<IContentBgColor>(({ theme, $backgroundcolor, $borderradius, $isnavbottom }) => ({
    backgroundColor: $backgroundcolor ? $backgroundcolor : theme.palette.background.paper,
    borderRadius: $borderradius ? $borderradius : 9,
    borderTopLeftRadius: $isnavbottom ? 9 : $borderradius,
    borderTopRightRadius: $isnavbottom ? 9 : $borderradius,
    borderBottomRightRadius: $isnavbottom ? 0 : $borderradius,
    borderBottomLeftRadius: $isnavbottom ? 0 : $borderradius,
}));
