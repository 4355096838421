import React, { useEffect, useState } from 'react';
import { Toolbar, Slide, useTheme, Box, useMediaQuery } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu"
import { IMyNavbar } from './interface';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppBarComp, IconButtonMenu, StackComp } from './styles';
import { pages } from './data';
import { returnBgColor } from "./functions";
import { MyButton } from '../MyButton/styles';
import { IPage } from './functions/interface';
import { useMain } from '../../Contexts/main';
import MyLogo from '../MyLogo';
import MyTypographyDesc from '../MyTypography/MyTypographyDesc';
import { logoBDRHoriz } from "../Foot";
import MyLink from '../MyLink';

export default function MyNavbar(props: IMyNavbar) {
    const { setOpenSwipe, setContact } = useMain();
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();
    const navigate = useNavigate();
    const [onPage, setPage] = useState<IPage>({ inicio: false, materias: false, patrocinadores: false, equipe: false, sobre: false });

    useEffect(() => {
        setPage({
            inicio: (location.pathname === '/'),
            materias: (location.pathname.includes('/materias')),
            patrocinadores: (location.pathname.includes('/patrocinadores')),
            equipe: (location.pathname.includes('/equipe')),
            sobre: (location.pathname === '/sobre'),

        });
    }, [location.pathname]);

    const handleClick = (APage: string) => {
        if (APage === '/contato') {
            setContact(true);
        } else {
            if (APage === location.pathname) {
                scrollTo({ top: 0, behavior: 'smooth' });
            } else {
                navigate(APage);
            }
        }
    };

    return (
        <nav>
            <Box position="absolute">
                <Slide appear={false} direction="down" in={true} >
                    <AppBarComp elevation={0} >
                        <Toolbar>
                            <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <MyLogo logo={logoBDRHoriz} setarRouter={(APath) => handleClick(APath)} />
                                <StackComp>
                                    {pages.map((data, key) => (
                                        <Box key={key} pr={1}>
                                            <MyLink disabled={data.router === "/contato"} href={data.router}>
                                                <MyButton
                                                    disabled={isSm}
                                                    disableElevation
                                                    variant='contained'
                                                    theme={theme}
                                                    key={data.page}
                                                    onClick={() => handleClick(data.router)}
                                                    bgcolorselect={returnBgColor(data.page, onPage, 'transparent', 'transparent')}
                                                    colortypo={returnBgColor(data.page, onPage, theme.palette.primary.main, theme.palette.primary.main)}
                                                    colorhover={theme.palette.primary.main}
                                                    bgcolorhover={'rgb(255 255 255 / 4%)'}
                                                    size="medium"
                                                    startIcon={data.icon}
                                                    children={
                                                        <MyTypographyDesc
                                                            fontFamily="l-bold"
                                                            // component={data.router === "/" ? "h1" : "h2"}
                                                            style={{ color: returnBgColor(data.page, onPage, theme.palette.primary.light, theme.palette.primary.main) }}
                                                            fontxs={theme.typography.button.fontSize.toString()}
                                                            fontsm={theme.typography.button.fontSize.toString()}
                                                            fontmd={theme.typography.button.fontSize.toString()}
                                                            fontlg={theme.typography.button.fontSize.toString()}
                                                            fontxl={theme.typography.button.fontSize.toString()}
                                                            children={data.page}
                                                        />
                                                    }
                                                />
                                            </MyLink>
                                        </Box>
                                    ))}
                                </StackComp>
                                <IconButtonMenu
                                    disabled={!isSm}
                                    edge="start"
                                    color="primary"
                                    onClick={() => setOpenSwipe(true)}
                                >
                                    <MenuIcon style={{ color: theme.palette.primary.light }} />
                                </IconButtonMenu>
                            </Box>
                        </Toolbar>
                    </AppBarComp>
                </Slide>
                <Toolbar />
            </Box>
        </nav>
    );
}
