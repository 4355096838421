import { Box, CardMedia, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Waypoint } from "react-waypoint";
import { IMyImageLazzy } from "./interface";
import { useAnimation, motion } from "framer-motion";
import { ContentAnim, ContentBgColor } from "./styles";
import { transition } from "../MyMotionImgLazzy/data";
import { loadImage } from "../../Comum";

const C_DEFAULT_CONFIG_BG_LINEAR = 'rgba(0, 0, 0, 0), rgb(0 0 0 / 20%), rgb(0 0 0 / 35%), rgb(0 0 0 / 40%), rgba(0, 0, 0, 0.5))';

export default function MyImageLazzy({ image, height, width, brightness, backgrondLinear, borderRadius, disableBackgroundLinear, isEquipe, children, isnavbottom, backgroundColor, scale, backgroundPosition, isEnteringGif, title }: IMyImageLazzy) {
    const theme = useTheme();
    const [img, setImg] = useState('');
    const animContent = useAnimation();
    const animShadow = useAnimation();

    const hanldeEnter = () => {
        if (isEnteringGif) {
            setImg("");
            loadImage({
                onLoad: animO(1),
                src: image
            });
        } else {
            if (img === '') {
                loadImage({
                    onLoad: animO(1),
                    src: image
                });
            }
        }
    };

    const animO = (AOpacity: number): undefined => {
        setImg(image);
        // return animContent.start({ opacity: 0 }).then(() => animShadow.start({ opacity: 0 }));
    };

    useEffect(() => {
        if (img !== '') {
            animContent.start({ opacity: 1 });
            animShadow.start({ opacity: disableBackgroundLinear ? 0 : 1 });
        }
    }, [img]);

    return (
        <Waypoint
            onEnter={hanldeEnter}
            onLeave={() => { }}
            children={
                <ContentBgColor
                    theme={theme}
                    $backgroundcolor={backgroundColor}
                    $borderradius={borderRadius}
                    $isnavbottom={isnavbottom ? true : false}
                >
                    <ContentAnim
                        theme={theme}
                        initial={{ opacity: 0 }}
                        animate={animContent}
                        transition={transition}
                        height={height}
                        width={width}
                        isequipe={isEquipe}
                    >
                        <Box
                            title={title}
                            style={{
                                height: 'inherit',
                                width: 'inherit',
                                borderRadius: 'inherit',
                                backgroundImage: `url(${img})`,
                                // backgroundImage: disableBackgroundLinear ? `url(${img})` : img ? `linear-gradient(${backgrondLinear ? backgrondLinear : C_DEFAULT_CONFIG_BG_LINEAR}, url(${img})` : '',
                                backgroundSize: 'cover',
                                transform: `scale(${scale ? scale : 1})`,
                                backgroundPosition: backgroundPosition ? backgroundPosition : 'center',
                                backgroundRepeat: 'no-repeat',
                                transitionDuration: "0.3s",
                                transitionTimingFunction: "cubic-bezier(0.60, 0.60, 0.60, 0.60)"
                            }}
                            children={
                                <>
                                    <motion.div
                                        animate={animShadow}
                                        initial={{ opacity: 0 }}
                                        transition={transition}
                                        style={{
                                            position: 'absolute',
                                            zIndex: 0,
                                            top: 0,
                                            height: height,
                                            width: width,
                                            backgroundImage: `linear-gradient(${backgrondLinear ? backgrondLinear : C_DEFAULT_CONFIG_BG_LINEAR}`,
                                        }}
                                    />
                                    {children && children}
                                </>
                            }
                        />

                    </ContentAnim>

                </ContentBgColor >
            }
        />

    )
}