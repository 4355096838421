import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from "react-router-dom";
import { dataHelmet } from './data';
import { dataColab } from '../GridColab/data';
import dataEquipe from "../GridEquipe/data";
import { IDataMater, IGetYoutubeMoviesRes } from '../GridMater/MateriasItem/Materias/interface';
import axios, { AxiosResponse } from 'axios';
import { returnImgResolution } from '../GridMater/MateriasItem/Materias/utils';

const DESTAQUES_API_LINK = "https://eeuqtyd7jz5nvzwzwb5g2yqdqa0bhkqf.lambda-url.sa-east-1.on.aws"
const HISTORIAS_API_LINK = "https://2czay5gbbx7zpqvpzanem2t2uq0vvgoo.lambda-url.sa-east-1.on.aws"
const INSTURCOES_API_LINK = "https://falzrj33bqa2trfwq4zvtpvq7i0gbdbo.lambda-url.sa-east-1.on.aws"
const TENDENCIAS_API_LINK = "https://4qceteeefqslbpvgwmlohzutru0ysuxx.lambda-url.sa-east-1.on.aws"

export const ldJsonOrganization = {
    "@context": "https://schema.org",
    "@id": "https://www.bomdiarural.com/#organization",
    "@type": "Organization",
    "name": "Bom Dia Rural",
    "alternateName": ["BDR", "bdr", "bom dia rural", "Bom dia rural"],
    "url": "https://www.bomdiarural.com/",
    "logo": "https://d13x8u4oijb9vw.cloudfront.net/bom-dia-rural-logo.png",
    "contactPoint": [
        {
            "@type": "ContactPoint",
            "telephone": "+55-46-99925-3032",
            "contactType": "sales",
            "areaServed": "BR",
            "availableLanguage": ["PT-BR", "EN"]
        },
        {
            "@type": "ContactPoint",
            "telephone": "+55-46-99925-3032",
            "contactType": "technical support",
            "areaServed": "BR",
            "availableLanguage": ["PT-BR", "EN"]
        },
        {
            "@type": "ContactPoint",
            "telephone": "+55-46-99925-3032",
            "contactType": "customer support",
            "areaServed": "BR",
            "availableLanguage": ["PT-BR", "EN"]
        }
    ],
    "sameAs": [
        "https://www.youtube.com/BomDiaRuralTV",
        "https://www.facebook.com/bomdiarural",
        "https://www.instagram.com/bomdiaruraltv/"
    ]
}

export default function MyHelmet() {
    const { pathname } = useLocation();
    const linkWebsite = process.env.REACT_APP_LINK_WEBSITE + pathname;
    const isHome = pathname === "/";
    const isMaterias = pathname === "/materias";

    const [dataMovie, setDataMovie] = useState<IDataMater[] | undefined>(undefined);

    useEffect(() => {
        hanldeLoad();
    }, [isMaterias]);

    const hanldeLoad = async () => await hanldeLoadMovie();

    const verifyAndPushMovie = (AData: AxiosResponse<IGetYoutubeMoviesRes, any>, ANewData: IDataMater[] = []) => {
        AData.data.items.map((data => {
            ANewData.push({
                description: data.snippet.description,
                img: returnImgResolution(data.snippet.thumbnails),
                title: data.snippet.title,
                videoId: data.snippet.resourceId.videoId,
                publishedAt: data.snippet.publishedAt
            });
        }));
    }

    const hanldeLoadMovie = async () => {
        if (isMaterias && !dataMovie) {
            let newData: IDataMater[] = [];
            await axios.get<IGetYoutubeMoviesRes>(DESTAQUES_API_LINK).then((data) => verifyAndPushMovie(data, newData)).catch((err) => console.log(err));
            await axios.get<IGetYoutubeMoviesRes>(HISTORIAS_API_LINK).then((data) => verifyAndPushMovie(data, newData)).catch((err) => console.log(err));
            await axios.get<IGetYoutubeMoviesRes>(INSTURCOES_API_LINK).then((data) => verifyAndPushMovie(data, newData)).catch((err) => console.log(err));
            await axios.get<IGetYoutubeMoviesRes>(TENDENCIAS_API_LINK).then((data) => verifyAndPushMovie(data, newData)).catch((err) => console.log(err));
            setDataMovie(newData);
        }
    }

    function returnDataColab(ADesc: boolean) {
        const id = pathname.replaceAll("/patrocinadores/", "");
        const dataReturn = dataColab.find(data => data.search.includes(id));
        return dataReturn ? ADesc ? (dataReturn.descricao1) : (dataReturn.nome + " " + dataReturn.sobreNome).trim() : "";
    }

    function returnDataEquipe(ADesc: boolean) {
        const id = pathname.replaceAll("/equipe/", "");
        const dataReturn = dataEquipe.find(data => data.search.includes(id));
        return dataReturn ? ADesc ? (dataReturn.cargo) : (dataReturn.nome + " " + dataReturn.sobreNome).trim() : "";

    }

    const returnDescTitle = (ADesc: boolean) => {
        switch (pathname) {
            case "/": return ADesc ? dataHelmet.index.desc : dataHelmet.index.title;
            case "/materias": return ADesc ? dataHelmet.materias.desc : dataHelmet.materias.title;
            case "/patrocinadores": return ADesc ? dataHelmet.patrocinadores.desc : dataHelmet.patrocinadores.title;
            case "/equipe": return ADesc ? dataHelmet.equipe.desc : dataHelmet.equipe.title;
            case "/sobre": return ADesc ? dataHelmet.sobre.desc : dataHelmet.sobre.title;
            default: {
                if (pathname.includes("/equipe/")) {
                    return returnDataEquipe(ADesc);
                } else if (pathname.includes("/patrocinadores/")) {
                    return returnDataColab(ADesc);
                } else return "";
            }
        }
    }

    const isSubdomain = () => {
        switch (pathname) {
            case "/": return false;
            case "/materias": return false;
            case "/patrocinadores": return false;
            case "/equipe": return false;
            case "/sobre": return false;
            default: {
                if (pathname.includes("/equipe/")) {
                    return true;
                } else if (pathname.includes("/patrocinadores/")) {
                    return true;
                }
            }
        }
    }

    const isSubDomainEquipe = () => pathname.includes("/equipe/");
    const isSubDomainMater = () => pathname.includes("/materias/");
    const returnSubDomain = () => isSubDomainEquipe() ? "/equipe" : "/patrocinadores";
    const returnTitleSubDomain = () => isSubDomainEquipe() ? "Equipe" : "Patrocinadores";


    const ldJsonWebSite = {
        "@context": "http://schema.org",
        "@id": "https://www.bomdiarural.com/#webpage",
        "@type": "WebPage",
        "name": "Bom Dia Rural - O Programa de quem planta e produz.",
        "description": returnDescTitle(true),
        "url": linkWebsite,
        "image": "https://d13x8u4oijb9vw.cloudfront.net/bom-dia-rural-logo.png"
    }

    const ldJsonBreadcrumb = {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": "1",
                "name": returnDescTitle(false)
            }
        ]
    }

    const ldJsonBreadcrumbSubDomain = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": "1",
                "item": {
                    "@id": process.env.REACT_APP_LINK_WEBSITE + returnSubDomain(),
                    "type": "WebPage",
                    "url": process.env.REACT_APP_LINK_WEBSITE + returnSubDomain(),
                    "name": returnTitleSubDomain()
                }
            },
            {
                "@type": "ListItem",
                "position": "2",
                "name": returnDescTitle(false)
            }
        ]
    }

    return (
        <Helmet>
            <title>{returnDescTitle(false)}</title>
            <meta name="description" content={returnDescTitle(true)} />
            <link rel="canonical" href={linkWebsite} />

            <meta property="og:title" content={returnDescTitle(false)} />
            <meta property="og:description" content={returnDescTitle(true)} />
            <meta property="og:url" content={linkWebsite} />

            <meta name="twitter:title" content={returnDescTitle(false)} />
            <meta name="twitter:description" content={returnDescTitle(true)} />

            <script type="application/ld+json">
                {JSON.stringify(ldJsonOrganization)}
            </script>

            {
                isHome ?
                    <script type="application/ld+json">
                        {JSON.stringify(ldJsonWebSite)}
                    </script>
                    :
                    !isSubDomainMater() &&
                    <script type="application/ld+json">
                        {JSON.stringify(isSubdomain() ? ldJsonBreadcrumbSubDomain : ldJsonBreadcrumb)}
                    </script>
            }

            {/* {isMaterias &&
                dataMovie?.map((e, key) => (
                    <script key={key} type="application/ld+json">
                        {JSON.stringify(
                            {
                                "@context": "https://schema.org",
                                "@type": "VideoObject",
                                "name": e.title,
                                "description": e.description,
                                "thumbnailUrl": [
                                    e.img
                                ],
                                "uploadDate": e.publishedAt,
                                "contentUrl": "https://youtube.com/watch/?v=".concat(e.videoId),
                                "embedUrl": "https://www.youtube.com/embed/".concat(e.videoId),
                            }
                        )}
                    </script>
                ))
            } */}



        </Helmet>
    )
}