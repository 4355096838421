import React from "react";
import { Container, useTheme } from "@material-ui/core";
import MateriasItem from "./MateriasItem";
import MyLottie from "../MyLottie";
import descPrincMaterias from "../../Assets/animation/descPrincMaterias.json";
import { MyTransitionComp } from "../MyTransitionComp";
import animMateria from "../../Assets/animation/animMateria.json";
import animMateriaM from "../../Assets/animation/animMateriaM.json";
import MyAnimTextMain from "../MyAnimTextMain";
import { ImagemMaterias } from "./data";
import { ContentBgColor } from "./styles";
import { ItensLottieHtmlDescPrincMaterias, ItensLottieHtmlPrincMaterias } from "../LottieHtml/data";

export default function GridMater() {
  const theme = useTheme();

  return (
    <ContentBgColor $bgcolor={theme.palette.background.default} >
      <section>
        <ItensLottieHtmlPrincMaterias />
        <MyAnimTextMain title="" isMovie={false} anim={animMateria} animM={animMateriaM} img={ImagemMaterias} />
      </section>
      <section>
        <Container style={{ paddingTop: 40, paddingBottom: 35 }} maxWidth="xs">
          <ItensLottieHtmlDescPrincMaterias />
          <MyLottie animation={descPrincMaterias} scroll />
        </Container>
      </section>
      {/* <CardHoverUp /> */}
      <MateriasItem />
    </ContentBgColor>
  );
}