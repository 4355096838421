import React from "react";
import { useTheme } from "@material-ui/core";
import DescCard from "../DescCard";
import iProps from "./interface";
import { useNavigate } from "react-router";
import MyPressMotion from "../../MyPressMotion";
import MyFadeUp from "../../MyFadeUp";
import MyImageLazzy from "../../MyImageLazzy";
import MyLink from "../../MyLink";
import { brightnessImageIntro } from "../../../Comum";
import { CardActionAreaComp, CardComponent, ContentBgColor, ContentDescCard, ContentPrinc, ContentSec } from "./styles";

export default function CardComp(props: iProps) {
  const theme = useTheme();
  const navigate = useNavigate();

  function onClick() {
    setTimeout(() => {
      navigate(props.datacard.pathName);
    }, 300);
  }

  return (
    <MyFadeUp fade>
      <ContentPrinc>
        <ContentBgColor theme={theme} >
          <MyPressMotion onClick={() => onClick()}>
            <MyLink href={props.datacard.pathName}>
              <ContentSec>
                <CardActionAreaComp disableRipple >
                  <CardComponent>
                    <MyImageLazzy
                      title={props.datacard.title}
                      brightness={brightnessImageIntro.toString()}
                      image={props.datacard.img}
                      height='350px'
                      width='100%'
                    />
                  </CardComponent>
                  <ContentDescCard children={<DescCard {...props} />} />
                </CardActionAreaComp>
              </ContentSec>
            </MyLink>
          </MyPressMotion>
        </ContentBgColor>
      </ContentPrinc>
    </MyFadeUp>
  );
}
