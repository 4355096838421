import { IconeCresol, logoCresolVertical200 } from "../Patroc/data";
const imgCresolItem = "https://cresol.com.br/wp-content/uploads/2020/05/Sede-Nacional-Cresoll.jpg";

const descCresol1 = "Fornecendo soluções financeiras com excelência por meio do relacionamento para gerar desenvolvimento dos cooperados, de seus empreendimentos e da comunidade.";
const descCresol2 = "Fazendo parte da Cresol Confederação, que abrange 17 estados e mais de 630 mil cooperados, a Cresol trabalha com foco no atendimento personalizado, fornecendo soluções financeiras para gerar desenvolvimento dos cooperados, de seus empreendimentos e de toda comunidade.";

const imgGuabiju = "https://d13x8u4oijb9vw.cloudfront.net/fazenda-guabiju-logo.png";
const imgGuabijuItem = imgGuabiju;
const descGuabiju1 = "A Fazenda Guabiju foi idealizada por seus sócios investidores no ano de 2008, com o propósito de desenvolver bezerros precoces. Com foco na busca constante por conhecimento e tecnologias aplicadas à produção animal.";
const descGuabiju2 = "Com foco na busca constante por conhecimento e tecnologias aplicadas à produção animal, o nome Guabiju passou a ter destaque e tornou-se referência na região pela confiança e qualidade.";

export const vitralLogo = "https://d13x8u4oijb9vw.cloudfront.net/vitral-logo.png";
const descVitralSul = "A Vitral Sul é uma empresa que atua no ramo vidreiro há 26 anos no Paraná, Santa Catarina e Rio Grande do Sul.";
const descVitralSul1 = "Seus produtos diferenciados atraem a atenção dos mais variados setores: Vidraçaria, Metalúrgicas, Indústrias Moveleiras, Construção Civil, Arquitetos e Decoradores.";

const imgRedeVemViverLogo = "https://d13x8u4oijb9vw.cloudfront.net/redebemviver-logo-150x150.png";
const descRedeBemViver1 = "A Rede Bem Viver iniciou suas atividades em 2001 com 18 lojas varejistas de materiais de construção na Região Sudoeste do Estado do Paraná. A ideia deu certo e a Rede evoluiu atualmente são 56 lojas associadas, sendo em 53 cidades nas regiões do Oeste, Sudoeste Campos Gerais no Paraná e as demais no Oeste Catarinense.";
const descRedeBemViver2 = "A Rede Bem Viver iniciou suas atividades em 2001 com 18 lojas varejistas de materiais de construção na Região Sudoeste do Estado do Paraná. A iniciativa foi dos empresários Flávio Antônio Pagnoncelli e Irineu Leopoldo Pagnoncelli.";

const imgDiferLogo = "https://d13x8u4oijb9vw.cloudfront.net/difer-ferros-logo.png";
const descDifer1 = "Oferece aos clientes uma ampla diversidade em ferros, aços, telhas galvalume, corte e dobra de chapas e uma linha completa de ferramentas, acessórios e abrasivos. Contamos com uma equipe de atendentes e colaboradores capazes de identificar, suprir e satisfazer as necessidades dos clientes, com rapidez e responsabilidade. Além disso, a empresa conta com frota própria de caminhões para agilizar as entregas de produtos.";

const imgFolhaAgricolaLogo = "https://d13x8u4oijb9vw.cloudfront.net/folha-agr-logo.png";
const descFolhaAgricola1 = "O Folha Agrícola é um jornal impresso e digital dirigido ao produtor rural e a todos os envolvidos na rede produtiva do agronegócio paranaense.";
const descFolhaAgricola2 = "Trazemos temas atuais abordados por profissionais de renome, para que o leitor esteja sempre bem informado - pecuária de leite e corte, avicultura, ovinocultura, produção de grãos e outros assuntos norteiam nossas pautas";

const imgSolPrime = 'https://d13x8u4oijb9vw.cloudfront.net/sol-prime.png';
const descSolPrime1 = 'Empresa especializada em vendas, instalação e manutenção de sistemas fotovoltaicos.';

const imgRevesul = 'https://d13x8u4oijb9vw.cloudfront.net/sol-prime.png';
const descRevesul = "Concessionária Volkswagen para Francisco Beltrão e região.";

const imgGovernoParana = 'https://d13x8u4oijb9vw.cloudfront.net/assembleia-legislativa-pr.png';
const descAssembleiaLegislativaPR = 'A Assembleia exerce o Poder Legislativo do Estado. Tem como funções primordiais avaliar projetos e outras proposições legislativas, além de fiscalizar os atos do Poder Executivo. Também conhecida como a "Casa de Leis", é o lugar de deliberação dos deputados, ou seja, onde os parlamentares podem debater questões de interesse da sociedade.';

export const dataColab = [
  {
    master: true,
    search: "cresol",
    nome: "Cresol",
    sobreNome: "",
    cargo: "Cooperativa de Crédito.",
    descricao1: descCresol1,
    descricao2: descCresol2,
    img: logoCresolVertical200,
    imgItem: imgCresolItem,
    hexa: "#00604f",
    social: {
      facebook: 'coop.cresol',
      instagram: 'cresol.coop'
    },
    link: 'https://www.youtube.com/embed/1tZJusCoQFk',
  },
  {
    master: false,
    search: "cresol-tradicao",
    nome: "Cresol",
    sobreNome: "Tradição",
    cargo: "Cooperativa de Crédito.",
    descricao1: descCresol1,
    descricao2: descCresol2,
    img: IconeCresol,
    imgItem: imgCresolItem,
    hexa: "#00604f",
    social: {
      facebook: 'coopcresoltradicao',
      instagram: 'cresoltradicao'
    },
    link: 'https://www.youtube.com/embed/1tZJusCoQFk',
  },
  {
    master: false,
    search: "vitral-sul",
    nome: "Vitral",
    sobreNome: "Sul",
    cargo: "Alumínios e Ferragens.",
    descricao1: descVitralSul,
    descricao2: descVitralSul1,
    img: vitralLogo,
    imgItem: vitralLogo,
    hexa: "#ffec00",
    social: {
      facebook: 'VitralSul',
      instagram: 'vitralsul'
    },
    link: 'https://www.youtube.com/embed/kf26w4E4d5o?si=lPjiTFeL7O4uNgDZ',
  },
  {
    master: false,
    search: "fazenda-guabiju",
    nome: "Fazenda",
    sobreNome: "Guabiju",
    cargo: "Fazenda de Bovinos.",
    descricao1: descGuabiju1,
    descricao2: descGuabiju2,
    img: imgGuabiju,
    imgItem: imgGuabijuItem,
    hexa: "#45392b",
    social: {
      facebook: 'fazendaguabiju',
      instagram: 'guabiju_silagem'
    },
    link: 'https://www.youtube.com/embed/SOedDdEaAnQ?si=e705RPBnwPNIMryG',
  },
  {
    master: false,
    search: "rede-bem-viver",
    nome: "Rede",
    sobreNome: "Bem Viver",
    cargo: "Seu amigo de construção.",
    descricao1: descRedeBemViver1,
    descricao2: descRedeBemViver2,
    img: imgRedeVemViverLogo,
    imgItem: imgRedeVemViverLogo,
    hexa: "#f49b3e",
    social: {
      facebook: 'redebemviveroficial',
      instagram: 'redebemviveroficial'
    },
    link: 'https://www.youtube.com/embed/n3LLjZ0hG40?si=uMpASimFKh7CT6KK',
  },
  {
    master: false,
    search: "difer-ferros",
    nome: "Difer",
    sobreNome: "Ferros",
    cargo: "Distribuidora de ferro e aço.",
    descricao1: descDifer1,
    descricao2: "",
    img: imgDiferLogo,
    imgItem: imgDiferLogo,
    hexa: "#2f3192",
    social: {
      facebook: 'diferferross',
      instagram: 'diferferros'
    },
    link: 'https://www.youtube.com/embed/n3LLjZ0hG40?si=uMpASimFKh7CT6KK',
  },
  // {
  //   master: false,
  //   search: "folha-agricola",
  //   nome: "Folha",
  //   sobreNome: "Agrícola",
  //   cargo: "Jornal impresso e digital.",
  //   descricao1: descFolhaAgricola1,
  //   descricao2: descFolhaAgricola2,
  //   img: imgFolhaAgricolaLogo,
  //   imgItem: imgFolhaAgricolaLogo,
  //   hexa: "#055153",
  //   social: {
  //     facebook: 'jaimeheuert',
  //     instagram: 'folhaagricola'
  //   },
  //   link: 'https://www.youtube.com/embed/5izf5xQFEGQ?si=e7fzE7dm45bXTxt2',
  // },
  {
    master: false,
    search: "sol-prime",
    nome: "Sol",
    sobreNome: "Prime",
    cargo: "Energia Solar.",
    descricao1: descSolPrime1,
    descricao2: '',
    img: imgSolPrime,
    imgItem: imgSolPrime,
    hexa: "#104143",
    social: {
      facebook: 'solprimeenergiasolar',
      instagram: 'sol.prime'
    },
    link: 'https://www.youtube.com/embed/8Zzg8jZ8NgQ?si=W7meDNWtncM4HU3V',
  },
  /*{
    master: false,
    search: "revesul-veiculos",
    nome: "Revesul",
    sobreNome: "",
    cargo: "Concessionária Volkswagen.",
    descricao1: descRevesul,
    descricao2: '',
    img: imgRevesul,
    imgItem: imgRevesul,
    hexa: "#01052e",
    social: {
      facebook: 'revesulrev',
      instagram: 'revesul_vw'
    },
    link: 'https://www.youtube.com/embed/8Zzg8jZ8NgQ?si=W7meDNWtncM4HU3V',
  },*/
  // {
  //   master: false,
  //   search: "assembleia-legislativa",
  //   nome: "Assembleia Legislativa",
  //   sobreNome: "do Paraná",
  //   cargo: "Órgão Legislativo.",
  //   descricao1: descAssembleiaLegislativaPR,
  //   descricao2: '',
  //   img: imgGovernoParana,
  //   hexa: "#326593",
  //   social: {
  //     facebook: 'assembleialegislativapr',
  //     instagram: 'assembleiaparana'
  //   },
  //   link: 'https://www.youtube.com/embed/I327Jwn6wHs?si=qZXwLL2lvLcPcZcH',
  // },
];


export const styleGrid = {
  width: "100%",
  margin: 0
}

export const propStyleGrid = {
  container: true,
  style: styleGrid
};