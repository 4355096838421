import React from 'react';
import "./styles.css";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import { motion, useAnimation } from "framer-motion";
import ArrowBack from '@material-ui/icons/ArrowBackIos';
import Arrow from "../Arrow";
import { transition } from "../data";
import { ContentArrowIcon } from '../styles';
import { C_HEIGHT_CARD_MATERIA } from '../../MyCard/MediaCard/data';

export default function LeftArrow() {
    const { getPrevElement, isFirstItemVisible, scrollToItem, visibleElements, initComplete } = React.useContext(VisibilityContext);
    const animO = useAnimation();

    React.useEffect(() => {
        if (visibleElements.length) handleAnimO()
    }, [isFirstItemVisible, visibleElements]);

    const handleAnimO = async () => {
        if (isFirstItemVisible) {
            await animO.start({
                opacity: 0
            })
            await animO.start({
                display: 'none'
            });
        } else {
            await animO.start({
                display: 'flex'
            });
            await animO.start({
                opacity: 1
            });
        }
    }

    // NOTE: for scroll 1 item
    const clickHandler = () => scrollToItem(getPrevElement(), "smooth", "center");
    return (
        <Arrow isLeft disabled={false} onClick={clickHandler}>
            <motion.div
                style={{
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: 'center',
                    height: C_HEIGHT_CARD_MATERIA,
                    zIndex: 4,
                    backgroundImage: "linear-gradient(1.5708rad, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0) 100%)"
                }}
                initial={{ opacity: 0 }}
                animate={animO}
                transition={transition}
            >
                <ContentArrowIcon>
                    <ArrowBack style={{ height: 25, width: 25 }} color={'primary'} />
                </ContentArrowIcon>
            </motion.div >
        </Arrow >
    );
}
