import React, { useEffect } from "react";
import { useAnimation } from "framer-motion";
import { useState } from "react";
import { Waypoint } from "react-waypoint";
import { IMyMotionImgLazzy } from "./interface";
import { useTheme } from "@material-ui/core";
import { Content, ContentShadow, ContentImg } from "./styles";
import { transition } from "./data";
import { loadImage } from "../../Comum";

export default function MyMotionImgLazzy({ src, height, title }: IMyMotionImgLazzy) {
    const theme = useTheme();
    const [img, setImg] = useState("");
    const animOpacity = useAnimation();
    const animShadow = useAnimation();

    useEffect(() => {
        animS(0);
        setImg(src);
    }, [src]);

    useEffect(() => {
        loadImage({
            onLoad: handleOpLoad(),
            src: img
        });
    }, [img]);

    const handleOpLoad = () => {
        animShadow.stop();
        animO(1);
        return animS(1);
    }

    const hanldeEnter = () => setImg(src);

    const animO = (AOpacity: number) => animOpacity.start({ opacity: AOpacity }, { duration: .3 });
    const animS = (AOpacity: number) => animShadow.start({ opacity: AOpacity }, { duration: .3 });

    return (
        <Waypoint
            onEnter={() => hanldeEnter()}
            onLeave={() => { }}
            children={
                <Content theme={theme}>
                    <ContentImg
                        title={title}
                        theme={theme}
                        height={height}
                        img={img}
                        initial={{ opacity: 0 }}
                        animate={animOpacity}
                        transition={transition}
                    />
                    <ContentShadow
                        theme={theme}
                        height={height}
                        initial={{ opacity: 0 }}
                        animate={animShadow}
                        transition={transition}
                    />
                </Content>
            }
        />
    );
}
