import React from "react";
import "./styles.css";
import ArrowForward from '@material-ui/icons/ArrowForwardIos';
import Arrow from "../Arrow";
import { Button, Box } from "@material-ui/core";
import { motion, useAnimation } from "framer-motion";

import { VisibilityContext } from "react-horizontal-scrolling-menu";

import { C_HEIGHT_CARD_MATERIA } from "../../MyCard/MediaCard/data";
import { ContentArrowIcon } from "../styles";
import { transition } from "../data";

export default function RightArrow() {
    const { getNextElement, isLastItemVisible, scrollToItem, visibleElements, } = React.useContext(VisibilityContext);
    const animO = useAnimation();

    React.useEffect(() => {
        if (visibleElements.length) handleAnimO()
    }, [isLastItemVisible, visibleElements]);

    const handleAnimO = async () => {
        if (isLastItemVisible) {
            await animO.start({
                opacity: 0
            })
            await animO.start({
                display: 'none'
            });
        } else {
            await animO.start({
                display: 'flex'
            });
            await animO.start({
                opacity: 1
            });
        }
    }

    // NOTE: for scroll 1 item
    const clickHandler = () => scrollToItem(getNextElement(), "smooth", "center");
    return (
        <Arrow isLeft={false} disabled={false} onClick={clickHandler}>
            <motion.div
                style={{
                    display: 'flex',
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: 'center',
                    zIndex: 4,
                    height: C_HEIGHT_CARD_MATERIA,
                    backgroundImage: "linear-gradient(1.5708rad, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7) 100%)"
                }}
                initial={{ opacity: 0 }}
                animate={animO}
                transition={transition}
            >
                <ContentArrowIcon>
                    <ArrowForward color={'primary'} />
                </ContentArrowIcon>
            </motion.div>
        </Arrow>
    );
}
