import React, { useEffect, useState } from "react";
import { BgImage } from "../styles";
import { IMyImage } from "./interface";
import { useLottiePlayer } from "../../../Contexts/LottiePlayer";
import { ContentMotion } from "./styles";
import { useAnimationControls } from "framer-motion";
import { Waypoint } from "react-waypoint";
import { useMain } from "../../../Contexts/main";
import { transition } from "./data";

export default function MyImage({ img, height, title }: IMyImage) {
    const animContent = useAnimationControls();
    const { setLoad } = useMain();
    const { setOpenLottie, setIsImageDesk, isGif, setIsGif } = useLottiePlayer();
    const [src, setSrc] = useState('');

    useEffect(() => {
        setIsImageDesk(true);
    }, []);

    useEffect(() => {
        handleSetLoad(true);
    }, [isGif]);

    useEffect(() => {
        setLoadImg();
    }, [src]);

    const setLoadImg = () => {
        var bgImg = new Image();
        bgImg.src = img;
        bgImg.onload = () => {
            setSrc(img);
            animO(1);
            setOpenLottie(true);
            handleSetLoad(false);
        };
    }

    const handleSetLoad = (ALoading: boolean) => {
        if (isGif) {
            setLoad(ALoading);
        } else {
            setLoad(ALoading)
        }
    }

    const animO = async (AOp: number) => {
        await animContent.start({ opacity: AOp })
    }

    const hanldeEnter = () => {
        if (isGif) {
            setSrc(img);
        }
    }

    const hanldeExit = () => {
        if (isGif) {
            setSrc('');
            animO(0);
        }
    }

    const handleError = () => {
        if (isGif) {
            setIsGif(false);
            setIsImageDesk(true);
        }
    };

    return (
        <Waypoint
            onEnter={hanldeEnter}
            onLeave={hanldeExit}
            children={
                <ContentMotion
                    initial={{ opacity: 0 }}
                    animate={animContent}
                    transition={transition}
                >
                    <BgImage
                        title={title}
                        height={height}
                        // onLoad={() => setOpenLottie(true)}
                        img={src}
                        scale={isGif ? "1" : "1.1"}
                        transition={isGif ? "ease 0s" : "ease 0.3s"}
                        onError={() => handleError()}
                    />

                </ContentMotion>
            }
        />
    )
}