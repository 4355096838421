import React, { useEffect, useState } from 'react';
// import { Global } from '@emotion/react';
import { Box, useTheme, CssBaseline, SwipeableDrawer, useMediaQuery } from "@material-ui/core"
import { useLocation, useNavigate } from 'react-router-dom';
import { ContainerComp, ContentButton, ContentPuller, Puller, Root } from './styles';
import { MyButton } from '../MyButton/styles';
import { pages } from '../MyNavbar/data';
import { IPage } from '../MyNavbar/functions/interface';
import { returnBgColor } from '../MyNavbar/functions';
import { useMain } from '../../Contexts/main';
import MyTypographyDesc from '../MyTypography/MyTypographyDesc';
import MyImageLazzy from '../MyImageLazzy';
import { ImagemMaterias } from '../GridMater/data';
import { useAnimation, motion } from "framer-motion";
import { transition } from '../CardHover/CardUp/data';

const drawerBleeding = 56;

export default function MySwipeableDrawer() {
  const animO = useAnimation();
  const { openSwipe, setOpenSwipe, setContact } = useMain();
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const location = useLocation();
  const [onPage, setPage] = useState<IPage>({ inicio: false, materias: false, patrocinadores: false, equipe: false, sobre: false });

  const animOpacity = async (AOp: number) => await animO.start({ opacity: AOp });

  useEffect(() => {
    setPage({
      inicio: (location.pathname === '/'),
      materias: (location.pathname.includes('/materias')),
      patrocinadores: (location.pathname.includes('/patrocinadores')),
      equipe: (location.pathname.includes('/equipe')),
      sobre: (location.pathname === '/sobre'),
    });
  }, [location.pathname]);

  useEffect(() => {
    handleClose();
  }, [matches]);

  useEffect(() => {
    if (openSwipe) {
      setTimeout(() => {
        animOpacity(1);
      }, 200);

    } else {
      animOpacity(0);
    }

  }, [openSwipe]);

  const toggleDrawer = (APath: string) => () => {
    setTimeout(() => {
      animOpacity(0).then(() => {
        setOpenSwipe(false);
        setTimeout(() => {
          if (APath === '/contato') {
            setContact(true);
          } else {
            if (APath === location.pathname) {
              scrollTo({ top: 0, behavior: 'smooth' });
            } else {
              navigate(APath);
            }
          }
        }, 100);
      });

    }, 100);
  };


  const handleClose = async () => {
    await animOpacity(0);
    setOpenSwipe(false)
  };

  return (
    <Root>
      <SwipeableDrawer
        ModalProps={{ keepMounted: true }}
        PaperProps={{ style: { backgroundColor: 'transparent' } }}
        anchor="bottom"
        open={openSwipe}
        onOpen={(e) => { }}
        onClose={handleClose}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen
      >
        <MyImageLazzy
          title=""
          backgrondLinear="rgba(0, 0, 0, 0), rgb(0 0 0 / 25%), rgb(0 0 0 / 50%), rgb(0 0 0 / 55%), rgba(0, 0, 0, 0.6))"
          isnavbottom={true}
          height="365px"
          width="100%"
          image={ImagemMaterias}
          children={
            <nav style={{ height: "100%" }} >
              <ContainerComp theme={theme}>
                <Box style={{ width: "100%" }} >
                  <ContentPuller theme={theme} $openmodal={openSwipe ? true : false} >
                    <Puller theme={theme} />
                    <Box p={4} />
                  </ContentPuller>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={animO}
                    transition={transition}
                  >
                    <ContentButton theme={theme}>
                      <Box display={"flex"} flexDirection="column" alignItems={"center"} justifyContent={"center"} width="100%" >
                        {pages.map((data, key) => (
                          <Box key={key} width="100%" pb={.8} pt={.8} >
                            <MyButton
                              fullWidth
                              theme={theme}
                              key={data.page}
                              onClick={toggleDrawer(data.router)}
                              bgcolorselect={returnBgColor(data.page, onPage, 'transparent', 'transparent')}
                              colortypo={returnBgColor(data.page, onPage, theme.palette.primary.main, theme.palette.primary.main)}
                              colorhover={theme.palette.primary.main}
                              bgcolorhover={'transparent'}
                              fontweighthover={0}
                              myfontweight={0}
                              size="large"
                              startIcon={data.icon}
                              variant="contained"
                              disableElevation
                              children={
                                <MyTypographyDesc
                                  fontFamily="l-bold"
                                  style={{ color: returnBgColor(data.page, onPage, theme.palette.primary.light, theme.palette.primary.main) }}
                                  fontxs={theme.typography.button.fontSize.toString()}
                                  fontsm={theme.typography.button.fontSize.toString()}
                                  fontmd={theme.typography.button.fontSize.toString()}
                                  fontlg={theme.typography.button.fontSize.toString()}
                                  fontxl={theme.typography.button.fontSize.toString()}
                                  children={data.page}
                                />}
                            />
                          </Box>
                        ))}
                      </Box>
                    </ContentButton>
                  </motion.div>
                </Box>
              </ContainerComp>
            </nav>
          }
        />
      </SwipeableDrawer>
    </Root>
  );
}
