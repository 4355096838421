import { Box, Container, useTheme } from "@material-ui/core";
import TextLast from "./TextLast";

import DoubleCard from "../DoubleTapCard";
import data from "./data.js";
import MyLottie from "../MyLottie";

import animSobre from "../../Assets/animation/animSobre.json";
import animSobreM from "../../Assets/animation/animSobreM.json";

import descNossaLocaliz from "../../Assets/animation/DescNossaLocaliz.json";

import DescComp from "./DescComp";
import MyMap from "../MyMap";
import MyAnimTextMain from "../MyAnimTextMain";
import StickyComp from "./StickyComp";
import { ContainerLottieDesc, ContentBgColor, ContentPadding } from "./styles";
import { ItensHtmlLottieMainAbout } from "../LottieHtml/data";
export const ImgBdrSobre =  "https://d13x8u4oijb9vw.cloudfront.net/Main-img.jpg";//"https://d13x8u4oijb9vw.cloudfront.net/about-img.jpeg";

export default function AboutComp() {
  const theme = useTheme();

  return (
    <ContentBgColor $bgcolor={theme.palette.background.default} >

      <section>
        <ItensHtmlLottieMainAbout />
        <MyAnimTextMain title="" isMovie={false} anim={animSobre} animM={animSobreM} img={ImgBdrSobre} />
      </section>

      <section>
        <DescComp desc={data.firstDesc} />
      </section>

      <section>
        <ContentPadding>
          <StickyComp
            title={data.secDescTitle}
            desc={data.secDesc}
            img={data.secImg}
          />
        </ContentPadding>
      </section>

      <section>
        <ContainerLottieDesc theme={theme} maxWidth="xs">
          <MyLottie animation={descNossaLocaliz} scroll />
        </ContainerLottieDesc>
        <Container maxWidth="lg">
          <MyMap />
          <DoubleCard />
        </Container>
      </section>

      <section>
        <Container maxWidth="lg">
          <TextLast desc={data.fourDesc} name={data.fourName} />
        </Container>
      </section>

    </ContentBgColor>
  );
}
