import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@material-ui/core";
import MyPressMotion from "../../MyPressMotion";
import { BottomBar, Content, DivScreenshot, Style } from "./styles";
import MyFadeUp from "../../MyFadeUp";
import MyImageLazzy from "../../MyImageLazzy";
import MyTypographyDesc from "../../MyTypography/MyTypographyDesc";
import MyLink from "../../MyLink";

export default function Card(props) {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const linkHref = location.pathname + "/" + props.search;

  function setarRouter() {
    setTimeout(() => {
      navigate(linkHref);
    }, 300);
  }

  return (
    <MyPressMotion onClick={() => setarRouter()} >
      <MyLink href={linkHref}>
        <MyFadeUp fade>
          <Style theme={theme} >
            <DivScreenshot
              children={
                <MyImageLazzy
                  title={props.title}
                  disableBackgroundLinear
                  backgroundColor={'transparent'}
                  height={props.master ? "200px" : "150px"}
                  width={props.master ? "200px" : "150px"}
                  image={props.image}
                  scale={props.search === "cresol-tradicao" ? 0.8 : 1}
                />
              }
            />
            <Content>
              <MyTypographyDesc
                theme={theme}
                fade={false}
                fontFamily="semi-bold"
                component="h3"
                style={{ color: theme.palette.secondary.dark, letterSpacing: .9, opacity: 0.8, textTransform: 'uppercase' }}
                fontxs={theme.typography.h6.fontSize.toString()}
                fontsm={theme.typography.h6.fontSize.toString()}
                fontmd={props.search === "assembleia-legislativa" ? '1.15rem' : theme.typography.h6.fontSize.toString()}
                fontlg={props.search === "assembleia-legislativa" ? '1.15rem' : theme.typography.h6.fontSize.toString()}
                fontxl={props.search === "assembleia-legislativa" ? '1.15rem' : theme.typography.h6.fontSize.toString()}
                children={props.title}
              />
              <MyTypographyDesc
                theme={theme}
                fade={false}
                fontFamily="regular"
                component="p"
                style={{ color: theme.palette.secondary.dark, opacity: .8, letterSpacing: 1 }}
                fontxs={theme.typography.body1.fontSize.toString()}
                fontsm={theme.typography.body1.fontSize.toString()}
                fontmd={theme.typography.body1.fontSize.toString()}
                fontlg={theme.typography.body1.fontSize.toString()}
                fontxl={theme.typography.body1.fontSize.toString()}
                children={props.description}
              />
              <BottomBar background={props.hexa} borderRadius={5} />
            </Content>
          </Style>
        </MyFadeUp>
      </MyLink>
    </MyPressMotion>
  )
}
